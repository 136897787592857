'use client';

import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useContext, useEffect, useState } from 'react';
import { ApiLink } from '~/components/Base/ApiLink';
import { AuthContext } from '~/contexts/authentication';
import { AnalyticsContext } from '~/services/analytics/core';
import {
  AnalyticsClickEvent,
  AnalyticsClickEventElementId,
  AnalyticsClickEventLocation,
  AnalyticsClickEventName,
} from '~/services/analytics/type';
import Avatar from '../../public/images/avatar.svg';
import Close from '../../public/images/downloads/close-icon.svg';
import Separator from '../../public/images/downloads/separator-icon.svg';
import TaliIcon from '../../public/images/downloads/tali-logo.svg';
import DropDown from '../../public/images/drop-down.svg';
import LogoutIcon from '../../public/images/logout-icon.svg';
import MenuBar from '../../public/images/menu-bar.svg';
import Up from '../../public/images/up.svg';
import { addEvent } from '../../services/gtag';
import CanUpgradeButton from '../buttons/CanUpgrade';
import { LogoutLink } from '../buttons/LogoutLink';
import Text from '../display/Text';
import { useOutSideAerter } from '../useoutSideClickHandle';
import { PageURL } from './constant';
import { MenuActiveIndicator } from './menuActiveIndicator';
import { MobileAuthMenu, MobileLinkMenu } from './mobile';
import styles from './Navbar.module.scss';

const mobileBreakpoint = '(max-width: 1000px)';
const getStartedText = 'Get Started';

export default function Navbar() {
  const pathname = usePathname();
  const { user, isLoading, isAuthenticated } = useContext(AuthContext);
  const [menuBarClicked, setMenuBarClicked] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const { trackClick } = useContext(AnalyticsContext);

  // Drop Down Menu for Resources in new Navbar
  const [showHoverMenu, setShowHoverMenu] = useState<boolean>(false);

  useEffect(() => {
    if (isLoading) {
      const profileName = localStorage.getItem('profileName') || '';
      setName(profileName);
      return;
    }
    if (isAuthenticated) {
      setName(user?.firstName || '');
    }
  }, [isLoading, isAuthenticated, user]);

  const handleClick = () => {
    setDropDown(!dropDown);
  };
  const { dropDown, setDropDown, ref } = useOutSideAerter(false);

  const defaultMenu = (
    <div className={styles.menu}>
      <div className={styles.testHeader}>
        <div className={styles.taliLogo}>
          <Link href="/" prefetch={false}>
            <div>
              <Image
                src={TaliIcon}
                alt="Tali logo"
                className={styles.clickable}
              />
            </div>
          </Link>
        </div>
        <Link href={PageURL.features}>
          <div className={styles.linkWrapper} key="1">
            Product
            <MenuActiveIndicator url={PageURL.features} />
          </div>
        </Link>
        <Link href={PageURL.pricing}>
          <div className={styles.linkWrapper} key="2">
            Pricing
            <MenuActiveIndicator url={PageURL.pricing} />
          </div>
        </Link>
        <Link href={PageURL.faq}>
          <div className={styles.linkWrapper} key="3">
            FAQ
            <MenuActiveIndicator url={PageURL.faq} />
          </div>
        </Link>
        <Link href={PageURL.install}>
          <div className={styles.linkWrapper} key="4">
            Install
            <MenuActiveIndicator url={PageURL.install} />
          </div>
        </Link>

        {/* Drop Down Menu for Resources in new Navbar */}
        <div
          className={clsx(
            styles.linkWrapper,
            styles.pointer,
            styles.flexD,
            styles.flexRow,
          )}
          key="5"
          onMouseEnter={() => {
            setShowHoverMenu(true);
          }}
          onMouseLeave={() => {
            setShowHoverMenu(false);
          }}
        >
          <div className={styles.margin}>Resources</div>
          {!showHoverMenu ? (
            <Image src={DropDown} alt="click" />
          ) : (
            <Image src={Up} alt="click" />
          )}
          <div
            className={clsx(
              pathname === PageURL.resources
                ? styles.hoverActiveLinkIndicator
                : styles.hide,
            )}
          />
          {showHoverMenu && (
            <div className={styles.hoverMenu}>
              <Link href={PageURL.resources}>
                <div
                  className={clsx(styles.menuItem, styles.hoverMenuItem, {
                    [styles.selectedHoverItem]: pathname === PageURL.resources,
                  })}
                >
                  Blog
                </div>
              </Link>
              {/* <Link href={PageURL.taliNews}>
                <div
                  className={clsx(styles.menuItem, styles.hoverMenuItem, {
                    [styles.selectedHoverItem]: pathname === PageURL.taliNews,
                  })}
                >
                  Tali News
                </div>
              </Link> */}
              {/* <Link href={PageURL.guide}>
                <div
                  className={clsx(styles.menuItem, styles.hoverMenuItem, {
                    [styles.selectedHoverItem]: pathname === PageURL.guide,
                  })}
                >
                  Guide
                </div>
              </Link> */}
            </div>
          )}
        </div>
      </div>
      <div className={styles.testAuth}>
        {isLoading ? (
          <div className={styles.authPlaceHolder} />
        ) : !isAuthenticated ? (
          <>
            <ApiLink href="/api/auth/login">
              <div
                className={styles.linkWrapper}
                key="5"
                onClick={() => addEvent('login')}
              >
                Sign In
              </div>
            </ApiLink>

            <div key="6">
              <Image src={Separator} alt="Separator" key={'Separator'} />
            </div>
            <ApiLink href="/api/auth/login">
              <div
                key="6"
                className={styles.lastItem}
                onClick={() => {
                  trackClick(AnalyticsClickEvent.REGISTER_BUTTON_CLICKED, {
                    name: AnalyticsClickEventName.LOGIN,
                    location: AnalyticsClickEventLocation.HOMEPAGE,
                    elementId: AnalyticsClickEventElementId.NAVBAR_REGISTER,
                    elementText: getStartedText,
                  });
                  addEvent('navRegisterButton');
                }}
              >
                {getStartedText}
              </div>
            </ApiLink>
          </>
        ) : (
          <>
            <CanUpgradeButton className={styles.lastItemOnScroll} />
            <div key="6">
              <Image src={Separator} alt="Separator" key={'Separator'} />
            </div>
            <div className={styles.flexD} key="5">
              <div
                className={clsx(styles.flexD, styles.pointer)}
                onClick={handleClick}
                ref={ref}
                role="presentation"
              >
                <div className={styles.avatarWrapper}>
                  <Image src={Avatar} alt="profile" />
                </div>
                <div className={styles.margin}>{name}</div>
                {!dropDown ? (
                  <Image src={DropDown} alt="click" />
                ) : (
                  <Image src={Up} alt="click" />
                )}
              </div>
              <div
                className={clsx(styles.anchorMenu, {
                  invisible: !dropDown,
                })}
              >
                <Link
                  href={PageURL.profile}
                  className={styles.menuItem}
                  shallow={true}
                >
                  <div>Profile</div>
                </Link>
                <LogoutLink>
                  <div
                    className={`${styles.menuItem} ${styles.logoutWrapper}`}
                    role="presentation"
                  >
                    <div className={styles.marginR}>
                      <Image src={LogoutIcon} alt="logout" />
                    </div>
                    <span>Logout</span>
                  </div>
                </LogoutLink>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );

  const mobileMenu = (
    <div className={styles.openMenuBar}>
      <MobileAuthMenu />
      <div className={styles.mobileDivader} />
      <MobileLinkMenu
        href={PageURL.features}
        onClick={() => {
          setMenuBarClicked(false);
        }}
      >
        Product
      </MobileLinkMenu>
      <MobileLinkMenu
        href={PageURL.pricing}
        onClick={() => {
          setMenuBarClicked(false);
        }}
      >
        Pricing
      </MobileLinkMenu>
      <MobileLinkMenu
        href={PageURL.faq}
        onClick={() => {
          setMenuBarClicked(false);
        }}
      >
        FAQ
      </MobileLinkMenu>
      <MobileLinkMenu
        href={PageURL.install}
        onClick={() => {
          setMenuBarClicked(false);
        }}
      >
        Install
      </MobileLinkMenu>
      {/* Drop Down Menu for Resources in new Navbar */}
      <div
        ref={ref}
        className={styles.mobileLinkWrapper}
        key="2"
        onClick={() => {
          setDropDown(!dropDown);
        }}
      >
        {pathname === PageURL.resources && (
          <div className={styles.mobileActiveLinkIndicator} />
        )}
        <a>Resources</a>
        {!dropDown ? (
          <Image src={DropDown} alt="click" />
        ) : (
          <Image src={Up} alt="click" />
        )}
        {pathname === PageURL.resources && (
          <div className={styles.mobileActiveLinkIndicator} />
        )}
      </div>
      {dropDown && (
        <div className={styles.mobileHoverMenu}>
          <Link href={PageURL.resources}>
            <div
              className={clsx(styles.mobileHoverMenuItem, {
                [styles.mobileSelectedHoverItem]:
                  pathname === PageURL.resources,
              })}
            >
              Blog
            </div>
          </Link>
          {/* <Link href={PageURL.taliNews}>
            <div
              className={clsx(styles.mobileHoverMenuItem, {
                [styles.mobileSelectedHoverItem]: pathname === PageURL.taliNews,
              })}
            >
              Tali News
            </div>
          </Link>
          <Link href={PageURL.guide}>
            <div
              className={clsx(styles.mobileHoverMenuItem, {
                [styles.mobileSelectedHoverItem]: pathname === PageURL.guide,
              })}
            >
              Guide
            </div>
          </Link> */}
        </div>
      )}
    </div>
  );

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Update state if we move to/from mobile view
      const isMobileView = window.matchMedia(mobileBreakpoint).matches;
      if (!isMobileView) {
        setMenuBarClicked(false);
        /* Resources drop down menu in mobile navbar triggers the dropDown state

        If user clicked on Resources drop down menu to expand this sets dropDown state to be true, however,
        this also triggers the Profile dropDown to be true, so if user switches from mobile view to desktop view
        and user had enabled the Resources drop down, then the Profile dropDown will be enabled after they switch to 
        desktop view
        */
        setDropDown(false);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Mobile Menu
  if (menuBarClicked) {
    return (
      <div className={styles.mobileMenu}>
        <div className={styles.miniHeader}>
          <div className={styles.headerContainer}>
            <div className={styles.taliLogo}>
              <Link href="/" prefetch={false}>
                <div>
                  <Image
                    src={TaliIcon}
                    alt="Tali Logo"
                    className={styles.clickable}
                  />
                </div>
              </Link>
            </div>
            <div className={styles.flexDMobile}>
              {!isAuthenticated && !isLoading ? (
                <ApiLink href="/api/auth/login">
                  <div
                    key="6"
                    className={styles.lastItem}
                    onClick={() => {
                      trackClick(AnalyticsClickEvent.REGISTER_BUTTON_CLICKED, {
                        name: AnalyticsClickEventName.LOGIN,
                        location: AnalyticsClickEventLocation.HOMEPAGE,
                        elementId: AnalyticsClickEventElementId.NAVBAR_REGISTER,
                        elementText: getStartedText,
                      });
                      addEvent('navRegisterButton');
                    }}
                  >
                    <Text size="semiBoldParagraph2" title={getStartedText} />
                  </div>
                </ApiLink>
              ) : (
                <Link
                  href={PageURL.profile}
                  className={styles.avatarWrapper}
                  shallow
                >
                  <Image src={Avatar} alt="profile" />
                </Link>
              )}
              <div className={styles.closeIconWrapper}>
                <button
                  onClick={() => {
                    setMenuBarClicked(!menuBarClicked);
                  }}
                  className={styles.closeIcon}
                >
                  <Image src={Close} alt="Close Menu" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.menuContainer}>{mobileMenu}</div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.miniHeader}>
        <div className={styles.headerContainer}>
          {defaultMenu}
          <div className={styles.flexDMobile}>
            <div className={styles.testHeader}>
              <div className={styles.taliLogo}>
                <Link href="/" prefetch={false}>
                  <div>
                    <Image
                      src={TaliIcon}
                      alt="Tali logo"
                      className={styles.clickable}
                    />
                  </div>
                </Link>
              </div>
            </div>
            <div className={styles.mobileBtnContainer}>
              {!isAuthenticated && !isLoading ? (
                <ApiLink href="/api/auth/login">
                  <div
                    key="6"
                    className={styles.lastItem}
                    onClick={() => {
                      trackClick(AnalyticsClickEvent.REGISTER_BUTTON_CLICKED, {
                        name: AnalyticsClickEventName.LOGIN,
                        location: AnalyticsClickEventLocation.HOMEPAGE,
                        elementId: AnalyticsClickEventElementId.NAVBAR_REGISTER,
                        elementText: getStartedText,
                      });
                      addEvent('navRegisterButton');
                    }}
                  >
                    <Text size="semiBoldParagraph2" title={getStartedText} />
                  </div>
                </ApiLink>
              ) : (
                <Link
                  href={PageURL.profile}
                  className={styles.avatarWrapper}
                  shallow
                >
                  <Image src={Avatar} alt="profile" />
                </Link>
              )}
              <button
                onClick={() => {
                  setMenuBarClicked(!menuBarClicked);
                }}
                className={styles.menuBar}
              >
                <div>
                  <Image src={MenuBar} alt="Mobile Menu" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.placeHolder}></div>
    </div>
  );
}
