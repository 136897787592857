'use client';

import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import styles from './Navbar.module.scss';

export const MenuActiveIndicator = ({ url }: { url: string }) => {
  const pathname = usePathname();

  return (
    <div
      className={clsx(
        pathname === url ? styles.activeLinkIndicator : styles.hide,
      )}
    />
  );
};
