import { useContext } from 'react';
import { AnalyticsContext } from '~/services/analytics/core';
import { useAuthData } from './use-auth-data';

export function useLogoutAnalytics() {
  const { isLoading } = useAuthData();
  const { trackLogout } = useContext(AnalyticsContext);
  if (isLoading) {
    return () => ({});
  }
  return trackLogout;
}

export function useAnonymousId() {
  const { getAnonymousId } = useContext(AnalyticsContext);

  return getAnonymousId;
}
