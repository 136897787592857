import { PropsWithChildren } from 'react';
import { ApiLink } from '../Base/ApiLink';
import { useLogoutAnalytics } from '~/hooks/useSegment';

export const LogoutLink = ({ children }: PropsWithChildren) => {
  const trackLogout = useLogoutAnalytics();
  return (
    <ApiLink href="/api/auth/logout" onClick={trackLogout}>
      {children}
    </ApiLink>
  );
};
