'use client';

import { ComponentProps, useContext } from 'react';
import { AuthContext } from '~/contexts/authentication';
import CanUpgradeButton from '../buttons/CanUpgrade';
import Link from 'next/link';
import { PageURL } from './constant';
import { usePathname } from 'next/navigation';
import { ApiLink } from '~/components/Base/ApiLink';
import styles from './Navbar.module.scss';
import { addEvent } from '../../services/gtag';
import { LogoutLink } from '../buttons/LogoutLink';

export const MobileLinkMenu = ({
  children,
  ...props
}: ComponentProps<typeof Link>) => {
  const pathname = usePathname();

  return (
    <Link {...props}>
      <div className={styles.mobileLinkWrapper} key="1">
        {pathname === props.href && (
          <div className={styles.mobileActiveLinkIndicator} />
        )}
        {children}
        {pathname === props.href && (
          <div className={styles.mobileActiveLinkIndicator} />
        )}
      </div>
    </Link>
  );
};
export const MobileAuthMenu = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const pathname = usePathname();

  return (
    <>
      {isAuthenticated ? (
        <>
          <CanUpgradeButton className={styles.lastItemOnScroll} />
          <Link href={PageURL.profile}>
            <div className={styles.mobileLinkWrapper} key="1">
              {pathname === PageURL.profile && (
                <div className={styles.mobileActiveLinkIndicator} />
              )}
              Profile
              {pathname === PageURL.profile && (
                <div className={styles.mobileActiveLinkIndicator} />
              )}
            </div>
          </Link>
          <LogoutLink>
            <div className={styles.mobileLinkWrapper} key="1">
              Logout
            </div>
          </LogoutLink>
        </>
      ) : (
        <ApiLink href="/api/auth/login">
          <div
            className={styles.mobileLinkWrapper}
            key="1"
            onClick={() => addEvent('login')}
          >
            Sign In
          </div>
        </ApiLink>
      )}
    </>
  );
};
