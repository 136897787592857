import { useRef, useEffect, useState } from 'react';

export const useOutSideAerter = (initValue: boolean) => {
  const ref = useRef<HTMLDivElement>(null);
  const [dropDown, setDropDown] = useState<boolean>(initValue);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickOutSide = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropDown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
    return () => {
      document.addEventListener('click', handleClickOutSide, true);
    };
  }, [ref]);
  return { dropDown, setDropDown, ref };
};
