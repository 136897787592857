export enum PageURL {
    features = '/features',
    pricing = '/pricing',
    resources = '/resources',
    faq = '/faq',
    install = '/install',
    profile = '/user-profile',
    guide = '/guide',
    taliNews = '/resources/categories/tali-news',
  }
  