import clsx from 'clsx';
import { AnchorHTMLAttributes } from 'react';

export const ApiLink = ({
  className,
  children,
  ...props
}: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <a {...props} className={clsx('cursor-pointer', className)}>
      {children}
    </a>
  );
};
