import { useContext } from 'react';
import { AuthContext, Plan } from '~/contexts/authentication';
import { ApiLink } from '~/components/Base/ApiLink';

const CanUpgradeButton = ({ className }: { className: string }) => {
  const { isAuthenticated, plan, isLoading, userId, isEnterprise } =
    useContext(AuthContext);

  // Hide button when loading or non-registered users
  if (!isAuthenticated || !userId || isLoading) {
    return <></>;
  }

  // It is not possible to upgrade from these plans so hide the button
  if ([Plan.pro].includes(plan) || isEnterprise) {
    return <></>;
  }

  let href = '/pricing';
  // Send the user to the pricing page on the free plan
  if (plan === Plan.free) {
    href = '/pricing';
  } else if (plan === Plan.premium) {
    href = `/api/payment-plan/${userId}/manage`;
  }

  const buttonText = 'Upgrade Plan';
  return (
    <ApiLink href={href}>
      <div className={className}>{buttonText}</div>
    </ApiLink>
  );
};

export default CanUpgradeButton;
